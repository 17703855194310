<template>
  <v-app>
    <v-sheet
      id="scrolling-techniques-2"
      class="overflow-y-auto"
      max-height="400"
    >
      <v-container style="height: 400px">
        <template>
          <v-card>
            <v-list
              v-for="(icono, index) of iconos"
              :key="index"
              flat
              subheader
              three-line
            >
              <v-subheader>{{ icono.titulo }}</v-subheader>

              <v-list-item-group  multiple active-class="">
                <v-list-item>
                  <template v-slot:default="{}">
                    <v-list-item-action>
                      <v-checkbox @click="$store.state.urlIcono= icono.nombre"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-row>
                        <v-col cols="6" md="6">
                          <template>
                            <v-img
                              height="50px"
                              width="50px"
                              :src="icono.nombre"
                            ></v-img>
                          </template>
                        </v-col>
                     </v-row>

                      <v-list-item-title></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </template>
      </v-container>
    </v-sheet>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    iconos: []
  }),
  created () {
    this.traerCategorias()
  },

  methods: {
    traerCategorias () {
      var myHeaders = new Headers()
      myHeaders.append('Authorization', 'Bearer ' + sessionStorage.token + '')
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      }

      var promise = Promise.race([
        fetch(`${this.$store.state.url}iconos.php`, requestOptions)
          .then(response => response.json()),
        new Promise((resolve, reject) =>
          setTimeout(() => reject(new Error('Timeout')), 520000)
        )
      ])
      promise.then(result => (this.iconos = result))
      promise.then(result => console.log(result))
      promise.catch(error => console.log(error))
    }
  }
}
</script>

<style></style>
