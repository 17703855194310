import Vue from 'vue'
import Vuex from 'vuex'
import { API_URL, API_URL_FILE, QR_URL } from '@/common/config'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pkComercio: '',
    url: API_URL,
    urlFile: API_URL_FILE,
    urlQr: QR_URL,
    color: '#AB47BC',
    dialogRutinas: false,
    nombreRutina: '',
    urlIcono: '',
    pkCliente: '',
    pkCategoria: '',
    cliente: '',
    telefono: '',
    desde: '',
    hasta: '',
    nombreCliente: '',
    dialogCrono: false
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
