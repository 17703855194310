import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Carga from '../views/Carga.vue'
import Contenedor from '../views/Contenedor.vue'
import Gimnasio from '../views/Gimnasio.vue'
import Creaiconos from '../views/CreaIconos.vue'
import Cronometro from '../components/Cronometro.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/carga',
    name: 'Carga',
    component: Carga
  },
  {
    path: '/cronometro',
    name: 'Cronometro',
    component: Cronometro
  },
  {
    path: '/creaiconos',
    name: 'Creaiconos',
    component: Creaiconos
  },
  {
    path: '/gimnasio/:id',
    name: 'Gimnasio',
    component: Gimnasio
  },
  {
    path: '/contenedor',
    name: 'Contenedor',
    component: Contenedor
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
