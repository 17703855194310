<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
       <Login/>
      </v-col>
        </v-row>
  </v-container>
</template>

<script>
import Login from './Login.vue'
export default {
  components: {
    Login
  },
  name: 'Inicio',

  data: () => ({
  })
}
</script>
