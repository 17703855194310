<template>
  <app id="inspire">
    <v-container>
    <template>
      <v-card>
        <template>
          <v-data-table
            :headers="headers"
            :items="iconos"
            :search="buscador"
            :loading="loading"
            locale="es"
            class="elevation-1"
            item-key=" pkcategoria"
          >
            <template v-slot:top>
              <v-toolbar
                dark
                :color="$store.state.color"
                elevation="3"
                class="fixed-bar"
              >
                <v-toolbar-title class="display-1 font-weight-medium"
                  >Iconos</v-toolbar-title
                >

                <v-spacer></v-spacer>
                <v-col cols="6">
                  <v-text-field
                    v-model="buscador"
                    class=""
                    label="Buscar"
                    hide-details
                    solo-inverted
                    style="opacity: 0.8"
                    prepend-inner-icon="mdi-magnify"
                  />
                </v-col>
                <v-spacer></v-spacer>

                <v-dialog v-model="dialog" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Nuevo
                    </v-btn>
                  </template>
                  <v-card>
                    <v-toolbar dark :color="$store.state.color" elevation="3">
                      <v-toolbar-title class="headline font-weight-medium">
                        {{ formSubTitle }}</v-toolbar-title
                      >

                      <v-spacer></v-spacer>

                      <v-btn text @click="close">ATRAS </v-btn>
                      <v-btn :disabled="!valid" dark @click="save()">
                        Guardar
                      </v-btn>
                    </v-toolbar>
                    <br />
                    <v-spacer></v-spacer>
                    <v-card-text>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-card elevation="3" class="pa-6" outlined>
                          <!-- <p class="font-weight-regular headline">{{ formTitle}} </p>
                              <v-divider></v-divider> -->
                          <v-card-text>
                            <v-row class="mx-0 mt-2">
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="editedItem.nombre"
                                  label="Url"
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card max-width="500px">
                    <v-card-title class="text-h5"
                      >Esta seguro de eliminar este item?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    :color="$store.state.color"
                  >
                    <v-icon medium class="mr-2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon color="red darken-1">
                    <v-icon medium @click="deleteItem(item)">
                      mdi-close
                    </v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize"> Reset </v-btn>
            </template>
            <template v-slot:[`item.nombre`]="{ item }">
            <img
              @click="zoon(item.nombre)"
              width="60"
              height="60"
              v-bind:src="item.nombre"
            />
            </template>
          </v-data-table>
        </template>
      </v-card>
    </template>
    </v-container>
  </app>
</template>

<script>
export default {
  components: {
  },
  data: () => ({
    desde: '',
    hasta: '',
    expanded: [],
    pkicono: '',
    iconos: [],
    loading: true,
    buscador: '',
    menu: false,
    date: new Date().toISOString().substr(0, 10),
    valid: true,
    emailRules: [
      (v) => !!v || 'E-mail es requiredo',
      (v) => /.+@.+/.test(v) || 'E-mail debe ser valido'
    ],
    numRules: [(x) => /[0-9]{1}/g.test(x) || 'Solo Números'],
    nameRules: [
      (v) => !!v || 'Campo requerido',
      (v) => (v && v.length <= 60) || 'Campo requerido'
    ],
    activePicker: null,
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'Icono', value: 'nombre' },
      { text: 'Opciones', value: 'actions', sortable: false }
    ],
    editedIndex: -1,
    editedItem: {
      nombre: '',
      pkicono: ''
    },
    defaultItem: {
      nombre: '',
      pkicono: ''
    }
  }),

  computed: {
    formSubTitle () {
      return this.editedIndex === -1 ? 'Carga Categoria' : 'Edita Categoria'
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },

  created () {
    this.traerCategorias()
  },
  mounted () {
    setTimeout(() => {
      this.loading = false
    }, 1000)
  },

  methods: {
    crearCategoria () {
      const obj = this
      var myHeaders = new Headers()
      myHeaders.append('Authorization', 'Bearer ' + sessionStorage.token + '')
      myHeaders.append('Content-Type', 'application/json')

      var raw = JSON.stringify({
        opcion: '1',
        nombre: this.editedItem.nombre
      })

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      }

      var promise = Promise.race([
        fetch(`${this.$store.state.url}iconos.php`, requestOptions)
          .then(response => response.json()),
        new Promise((resolve, reject) =>
          setTimeout(() => reject(new Error('Timeout')), 520000)
        )
      ])

      promise.then(result => console.log(result))
      promise.then(obj.$swal({
        title: 'Se creo con exito!!',
        text: '',
        icon: 'success'
      }))
      promise.catch(error => console.log(error))
      setTimeout(() => { this.traerCategorias() }, 3000)
    },
    editarCategoria () {
      // eslint-disable-next-line no-console
      const obj = this
      var myHeaders = new Headers()
      myHeaders.append('Authorization', 'Bearer ' + sessionStorage.token + '')
      myHeaders.append('Content-Type', 'application/json')

      var raw = JSON.stringify({
        id: this.pkicono,
        nombre: this.editedItem.nombre
      })

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      }

      var promise = Promise.race([
        fetch(`${this.$store.state.url}iconos.php`, requestOptions)
          .then(response => response.json()),
        new Promise((resolve, reject) =>
          setTimeout(() => reject(new Error('Timeout')), 520000)
        )
      ])

      promise.then(result => console.log(result))
      promise.then(obj.$swal({
        title: 'Se edito con exito!!',
        text: '',
        icon: 'success'
      }))
      promise.catch(error => console.log(error))
      setTimeout(() => { this.traerCategorias() }, 3000)
    },
    eliminarCategoria () {
      var myHeaders = new Headers()
      myHeaders.append('Authorization', 'Bearer ' + sessionStorage.token + '')
      // myHeaders.append('Authorization', 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IlBFUkFMVEEgIE1BUlRJTiIsImV4cCI6MTY2NjcwMjAyN30.zjw1YpvvPJK1B1EH2NPGZ9gUalembdu38fv2zLpD3jI')
      var raw = JSON.stringify({
        id: this.pkcategoria
      })
      var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      }

      var promise = Promise.race([
        fetch(`${this.$store.state.url}iconos.php`, requestOptions)
          .then(response => response.json()),
        new Promise((resolve, reject) =>
          setTimeout(() => reject(new Error('Timeout')), 520000)
        )
      ])
      promise.then(result => console.log(result))
      promise.catch(error => console.log(error))
      setTimeout(() => { this.traerCategorias() }, 3000)
    },

    traerCategorias () {
      var myHeaders = new Headers()
      myHeaders.append('Authorization', 'Bearer ' + sessionStorage.token + '')
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      }

      var promise = Promise.race([
        fetch(`${this.$store.state.url}iconos.php`, requestOptions)
          .then(response => response.json()),
        new Promise((resolve, reject) =>
          setTimeout(() => reject(new Error('Timeout')), 520000)
        )
      ])
      promise.then(result => (this.iconos = result))
      promise.then(result => console.log(result))
      promise.catch(error => console.log(error))
    },

    increment () {
      this.$store.commit('increment')
      // eslint-disable-next-line no-console
      console.log(this.$store.state.count)
    },

    initialize () {},
    editItem (item) {
      console.log(item)
      this.pkicono = item.id
      this.editedIndex = this.iconos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.pkicono = item.id
      this.editedIndex = this.iconos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.eliminarCategoria()
      this.iconos.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.resetValidation()
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1) {
        this.editarCategoria()
        this.resetValidation()
      } else {
        this.crearCategoria()
        this.resetValidation()
      }
      this.close()
    },
    validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    }
  }
}
</script>
