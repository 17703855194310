<!-- eslint-disable eqeqeq -->
<template>
  <v-app>
    <v-dialog height="900" max-width="600" v-model="dialogRecupera">
      <v-card >
        <v-card-text>Ingrese email para recuperar cuenta</v-card-text>
        <v-col md="12">
        <v-text-field label="E-mail" v-model="emailRecupera" ></v-text-field>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" persistent max-width="600px" min-width="360px">
      <div>
        <v-tabs
          v-model="tab"
          show-arrows
          background-color="deep-purple accent-4"
          icons-and-text
          dark
          grow
        >
          <v-tabs-slider color="purple darken-4"></v-tabs-slider>
          <v-tab v-for=" (i, index) in tabs" :key="index">
            <v-icon large>{{ i.icon }}</v-icon>
            <div class="caption py-1">{{ i.name }}</div>
          </v-tab>
          <v-tab-item>
            <v-card class="px-4">
              <v-card-text>
                <v-form ref="loginForm" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="loginEmail"
                        :rules="loginEmailRules"
                        label="E-mail"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="loginPassword"
                        :append-icon="show1 ? 'eye' : 'eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Password"
                        hint="At least 8 characters"
                        counter
                        @click:append="show1 = !show1"
                      ></v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="6" xsm="12"> </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                      <v-btn
                        x-large
                        block
                        :disabled="!valid"
                        color="success"
                        @click="validate"
                      >
                        Login
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="px-4">
              <v-card-text>
                <v-form ref="registerForm" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="nombre"
                        :rules="[rules.required]"
                        label="Nombre"
                        maxlength="20"
                        required
                        @change="traerLocalidades()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="apellido"
                        :rules="[rules.required]"
                        label="Apellido"
                        maxlength="20"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="telefono"
                        label="Telefono"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        :items="localidades"
                        item-text="nombre"
                        item-value="id"
                        v-model="pklocalidad"
                        label="Localidad"
                        required
                        :rules="[rules.required]"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="direccion"
                        label="Direccion"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="email"
                        :rules="emailRules"
                        label="E-mail"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Password"
                        hint="At least 8 characters"
                        counter
                        @click:append="show1 = !show1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        block
                        v-model="verify"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, passwordMatch]"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Confirm Password"
                        counter
                        @click:append="show1 = !show1"
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex ml-auto" cols="12" sm="3" xsm="12">
                      <v-btn
                        x-large
                        block
                        :disabled="!valid"
                        color="success"
                        @click="createUser"
                        >Registese</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </div>
    </v-dialog>
  </v-app>
</template>

<script> import swal from 'sweetalert'
export default {
  data: () => ({
    localidades: [],
    direccion: '',
    pklocalidad: '',
    error: '',
    dialogRecupera: false,
    dialog: true,
    tab: '',
    tabs: [
      { id: 1, name: 'Login', icon: 'mdi-account' }
      // { id: 1, name: 'Login', icon: 'mdi-account' },
      // { id: 2, name: 'Register', icon: 'mdi-account-outline' }
    ],
    valid: true,
    token: '',
    resultado: '',
    nombre: '',
    apellido: '',
    telefono: '',
    email: 'example@gmail.com',
    password: '12345678',
    verify: '',
    loginPassword: '12345678',
    loginEmail: '',
    loginEmailRules: [
      (v) => !!v || 'Required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail Invalido'
    ],
    emailRules: [
      (v) => !!v || 'Required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail Invalido'
    ],

    show1: false,
    rules: {
      required: (value) => !!value || 'Requerido.',
      min: (v) => (v && v.length >= 8) || 'Minimo 12 caracteres'
    }
  }),
  computed: {
    passwordMatch () {
      return () => this.password === this.verify || 'Password no valido'
    }
  },
  methods: {
    traerLocalidades () {
      const obj = this
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      }

      var promise = Promise.race([
        fetch(`${obj.$store.state.url}localidades.php`, requestOptions)
          .then(response => response.json()),
        new Promise((resolve, reject) =>
          setTimeout(() => reject(new Error('Timeout')), 520000)
        )
      ])

      promise.then(result => console.log(result))
      promise.then(result => (this.localidades = result))
      promise.catch(error => console.log(error))
    },
    emailRecupera () { console.log('emailRecupera') },
    validate () {
      sessionStorage.clear()
      sessionStorage.id = ''
      const obj = this
      if (this.$refs.loginForm.validate()) {
        var myHeaders = new Headers()
        myHeaders.append('Content-Type', 'text/plain')

        var raw = JSON.stringify({
          email: this.loginEmail,
          password: this.loginPassword
        })

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        }

        // eslint-disable-next-line no-inner-declarations
        async function asyncData () {
          const response = await fetch(
           `${obj.$store.state.url}login.php`,
           requestOptions
          )
          const data = await response.json()
          return data
        }
        const result = asyncData()
        result.then((result) => {
          sessionStorage.token = result.token
          sessionStorage.id = result.id
          sessionStorage.user = result.user
          if (result.error !== 'Invalid User') {
            this.$router.push('/contenedor')
          } else {
            swal({
              title: 'Email o Password incorrecto!!',
              text: 'Desea recuperar su cuenta!',
              icon: 'success',
              buttons: true,
              dangerMode: false
            })
              .then((willDelete) => {
                if (willDelete) {
                  this.dialogRecupera = true
                  // swal('Poof! Your imaginary file has been deleted!', {
                  //   icon: 'success'
                  // })
                } else {
                  swal('Vuelva a intentarlo dentro de 10 minutos')
                }
              })
          }
        })
      }
    },
    createUser () {
      if (this.$refs.registerForm.validate()) {
        const obj = this
        this.nombre = this.apellido + '  ' + this.nombre
        var myHeaders = new Headers()
        myHeaders.append('Content-Type', 'application/json')

        var raw = JSON.stringify({
          name: this.nombre.toUpperCase(),
          telefono: this.telefono,
          fklocalidad: this.pklocalidad,
          direccion: this.direccion,
          email: this.email,
          password: this.password
        })

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        }
        // eslint-disable-next-line no-inner-declarations
        async function asyncData () {
          const response = await fetch(
           `${obj.$store.state.url}register.php`,
           requestOptions
          )
          const data = await response.json()
          return data
        }
        const result = asyncData()
        result.then((data) => {
          console.log(data)
        })
        setTimeout(() => {
          console.log(this.error)
          if (this.resultado === 'USER_MAIL_EXISTS') {
            swal('El email utilizado ya existe!!')
            this.resultado = ''
          } else if (this.error !== 'USER_MAIL_EXISTS' && this.resultado !== '') {
            swal('Fue creado correctamente')
            this.resultado = ''
          } else if (this.error !== '') {
            swal('No fue creado, intentelo más tarde')
            this.error = ''
          }
        }, 3000)
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    }
  }
}
</script>
<style  scoped>
</style>
