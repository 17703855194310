<template>
  <v-app>
        <br>
            <v-card class="mx-auto">
                <v-spacer></v-spacer>
                    <qr-code
                    :text="`${$store.state.urlQr +  $store.state.pkCliente}`"
                    size="400"
                    color="black"
                    bg-color="white"
                    error-level="L">
                    </qr-code>
                    <br>
                    <v-card-text> <a :href="`${$store.state.urlQr +  $store.state.pkCliente}`"> {{`${$store.state.urlQr +  $store.state.pkCliente}`}} </a></v-card-text>
            </v-card>
  </v-app>
</template>

<script>
export default {

}
</script>
