<template>
  <v-app id="inspire">
    <templete v-if="dialogInicio"  style="margin-top: -5px">
        <v-card
            class="mx-auto my-12">
            <v-card-title>
          <v-img src='./inicio.jpg'>
          </v-img>
          <v-progress-linear
            indeterminate
            :color="$store.state.colorTexto"
            class="mb-0"
          ></v-progress-linear>
           </v-card-title>
        </v-card>
    </templete>
    <template v-if="!dialogInicio">
            <v-card>
                <v-app-bar
                    absolute
                    color="#fcb69f"
                    dark
                    shrink-on-scroll
                    src="https://picsum.photos/1920/1080?random"
                    scroll-target="#scrolling-techniques-2"
                >
                    <v-app-bar-title>{{rutinas[0].nombreGym}}</v-app-bar-title>
                    <template v-slot:img="{ props }">
                    <v-img
                        v-bind="props"
                        gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
                    ></v-img>
                    </template>
                </v-app-bar>
                <v-btn
                        absolute
                        fab
                        x-small
                        right
                        v-model="$vuetify.theme.dark"
                        style="margin-top:30px"
                        @click="$vuetify.theme.dark = ! $vuetify.theme.dark"
                    >
                    <v-icon v-if="$vuetify.theme.dark"  color="#FFFFFF" medium  >mdi-theme-light-dark</v-icon>
                    <v-icon v-if="!$vuetify.theme.dark"  color="#000000" medium  >mdi-theme-light-dark</v-icon>
                    </v-btn>
                <v-sheet
                    id="scrolling-techniques-2"
                    class="overflow-y-auto"
                    max-height="800"
                >
                <br> <br> <br> <br>
                        <v-divider></v-divider>
                    <v-card style="margin-top:35px"   height="35px" elevation="20"
                        class="d-flex justify-space-between mb-6 rounded-lg"
                        flat
                        tile
                        >
                        <v-row >
                          <v-col cols="6" md="6">
                            <div style="margin-top:5px">

                          <v-btn
                            @click="cargarDias()"
                            outlined
                            block
                              small
                              text
                              >Dias rutinas</v-btn>
                            </div>
                          </v-col>
                          <v-col cols="6" md="6">
                            <div style="margin-top:5px">
                          <v-btn
                              @click="cargarGrupo()"
                              outlined
                              block

                              small
                              text
                             >
                             Grupo Muscular
                            </v-btn>
                            </div>
                          </v-col>
                            </v-row>
                        </v-card>
                    <v-container style="height: 2500px">
                        <template v-if="accionDia">
                            <v-card v-for="(dia,i) in dias" :key="i">
                                <v-card-title @click="cargaRutinasDia(dia), accionDia= false">{{dia}}</v-card-title>
                            </v-card>
                        </template>
                        <template v-if="accionGrupo">
                            <v-card v-for="(grupo,i) in grupoRutinas" :key="i">
                                <v-card-title @click="cargaRutinasGrupo(grupo), accionGrupo= false">{{grupo}}</v-card-title>
                            </v-card>
                        </template>
                        <template>
                            <template  v-if="accionDia === false && accionGrupo === false">
                                <p style="text-align:center"> <b> {{tituloDia}} </b>          <v-btn   absolute
                        fab
                        small
                        outlined
                        right @click="$store.state.dialogCrono=true"><v-icon>mdi-timetable</v-icon></v-btn> </p>
                                <v-divider></v-divider>
                            </template>
                            <v-col style="margin-top: 0px; margin-left: -15px" v-for="(item, i) in rutinasLista" :key="i" cols="12">

                                <template v-if="accionDia === false && accionGrupo === false">
                                    <v-row>
                                     <v-col cols="4"  md="4">
                                         <v-hover>
                                            <template v-slot:default="{ hover }">
                                                <v-card
                                                    color="white"
                                                    :elevation="hover ? 24 : 6"
                                                        shaped
                                                        max-height="80"
                                                        max-width="80">
                                                    <v-img
                                                    width="80"
                                                    height="80"
                                                    :src="item.url"
                                                    @click="zoon(item.url,item.dia, item.nombre),prod=true"
                                                    >
                                                    </v-img>
                                                </v-card>
                                            </template>
                                         </v-hover>
                                        </v-col>
                                        <v-col cols="8" style="text-align: left;; margin-top: 0px" md="8">
                                            <v-hover>
                                            <template v-slot:default="{ hover }">
                                            <p  @click="zoon(item.url, item.dia, item.nombre),prod=true"  :elevation="hover ? 24 : 6" > {{
                                            item.nombre
                                            }}  <br> {{item.price}} <br>     </p>
                                            </template>
                                            </v-hover>
                                        </v-col>
                                    </v-row>
                                       <v-divider :color="$store.state.colorTexto"></v-divider>

                                </template>
                            </v-col>
                        </template>
                    </v-container>
                </v-sheet>
            </v-card>
            <v-card
             elevation="24"
             shaped >
                <v-responsive max-width="156">
                    <v-footer app height="72" inset>
                        <v-container fluid>
                            <v-row justify="space-around" class="mb-2">
                               <span class="group pa-2 ">
                                    <v-btn   :href="telefono"  icon >
                                        <v-icon :color="$store.state.colorTexto" large>mdi-phone</v-icon>
                                    </v-btn>
                                    <v-btn     @click="cargarDias()" icon >
                                        <v-icon :color="$store.state.colorTexto" large>mdi-home-outline</v-icon>
                                    </v-btn>
                                    <v-btn
                                           :href="whatsapp"
                                            icon
                                            >
                                            <v-icon large>mdi-share-variant</v-icon>
                                    </v-btn>
                                </span>
                            </v-row>
                        </v-container>
                        <v-spacer></v-spacer>
                    </v-footer>
                </v-responsive>
            </v-card>
    </template>
    <v-dialog v-model="$store.state.dialogCrono">
              <v-card >
                 <Cronometro></Cronometro>
              </v-card>
            </v-dialog>
    <v-dialog v-model="dialogImg" max-width="600px">
        <v-card>
          <v-img
            width="500"
            height="450"
            :src="imag1">
          </v-img>
         <v-card>
              <v-container>
                <div style="color:#455A64">
            <h3> {{nombre}} </h3> <br> {{detalle}}
          </div>
          </v-container>
          </v-card>
         <v-btn :color="$store.state.colorTexto" block @click="dialogImg = false">Cerrar</v-btn>
        </v-card>
      </v-dialog>
  </v-app>
</template>

<script>
import Cronometro from '@/components/Cronometro.vue'
export default {
  components: {
    Cronometro
  },
  data: () => ({
    nombre: '',
    detalle: '',
    url: '',
    dialogImg: false,
    loading: '',
    tituloDia: '',
    verListaDias: false,
    accionDia: true,
    accionGrupo: false,
    dias: [],
    rutinasLista: [],
    telefono: '',
    dialogInicio: false,
    rutinas: [],
    rutinaDia: '',
    nombreGym: '',
    localidad: '',
    direccion: '',
    grupoRutinas: [],
    whatsapp: '',
    imag1: '',
    name: ''
  }),
  created () {
    this.traerRutinas()
    this.dialogInicio = true
  },
  methods: {
    zoon (ima1, det, nombre) {
      this.nombre = nombre
      this.detalle = det
      this.imag1 = ima1
      this.dialogImg = true
    },
    traerRutinas () {
      const obj = this
      var myHeaders = new Headers()
      myHeaders.append('Authorization', 'Bearer ' + sessionStorage.token + '')
      var raw = JSON.stringify({
        fkcliente: this.$route.params.id,
        opcion: '2'
      })
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      }

      var promise = Promise.race([
        fetch(`${this.$store.state.url}rutinasCliente.php`, requestOptions)
          .then(response => response.json()),
        new Promise((resolve, reject) =>
          setTimeout(() => reject(new Error('Timeout')), 520000)
        )
      ])
      promise.then(result => (obj.rutinas = result))
      promise.then(result => console.log(result))
      promise.catch(error => console.log(error))
      // eslint-disable-next-line no-unused-expressions
      this.dialogInicio = false
      setTimeout(() => { this.dialogInicio = false }, 4000)
      // eslint-disable-next-line no-unused-expressions
      setTimeout(() => { this.telefono = 'tel:+54' + this.rutinas[0].telefono }, 4000)
      setTimeout(() => { this.cargarDias() }, 3000)
      setTimeout(() => { this.whatsapp = 'https://wa.me/+54' + this.rutinas[0].telefono + '/?text=' + this.rutinas[0].nombreGym + '  ' + this.rutinas[0].localidad + '  ' + this.rutinas[0].direccion + '' }, 5000)
    },
    cargarDias () {
      this.$store.state.telefono = this.rutinas[0].telefono
      this.$store.state.nombreCliente = this.rutinas[0].nombreCliente
      this.$store.state.desde = this.rutinas[0].desde
      this.$store.state.hasta = this.rutinas[0].hasta
      for (var i = 0; i < this.rutinas.length; i++) {
        if (this.rutinas[i].dia === 'LUNES') {
          this.dias.push(this.rutinas[i].dia)
        } else if (this.rutinas[i].dia === 'MARTES') {
          this.dias.push(this.rutinas[i].dia)
        } else if (this.rutinas[i].dia === 'MIERCOLES') {
          this.dias.push(this.rutinas[i].dia)
        } else if (this.rutinas[i].dia === 'JUEVES') {
          this.dias.push(this.rutinas[i].dia)
        } else if (this.rutinas[i].dia === 'VIERNES') {
          this.dias.push(this.rutinas[i].dia)
        } else if (this.rutinas[i].dia === 'SABADO') {
          this.dias.push(this.rutinas[i].dia)
        }
      }
      const dataArr = new Set(this.dias)
      const result = [...dataArr]
      this.dias = result
      this.accionDia = true
      this.verListaDias = true
      this.accionGrupo = false
    },
    cargaRutinasDia (dia) {
      this.rutinasLista = []
      this.rutinas.forEach(el => {
        if (el.dia === dia) {
          this.rutinasLista.push(el)
        }
      })
      this.tituloDia = dia
      this.rutinaDia = dia
    },
    cargarGrupo () {
      this.rutinas.forEach(el => {
        this.grupoRutinas.push(el.categoria)
      })
      const dataArr1 = new Set(this.grupoRutinas)
      const result1 = [...dataArr1]
      this.grupoRutinas = result1
      console.log(this.grupoRutinas)
      this.accionGrupo = true
      this.accionDia = false
      this.verListaDias = false
    },
    cargaRutinasGrupo (grupo) {
      this.rutinasLista = []
      this.rutinas.forEach(el => {
        if (el.categoria === grupo) {
          this.rutinasLista.push(el)
        }
      })
      this.tituloDia = grupo
      this.rutinaDia = grupo
    }
  }
}
</script>

<style>
   .div1 {
        margin-top: -40px;
        background-image: url("https://i.ibb.co/pJdhHCM/fondoapp.jpg");
      }
  .group {
  display: flex;
  flex: 1;
  justify-content: space-around;
}
</style>
