<template>
<v-app>
  <v-row v-if="imc">
        <v-col cols="12">
            <v-card height="912px" elevation="22">
              <br>
                <h1 style="text-align:center;font-size:20px">{{titulo}}</h1>
                    <v-btn
                    absolute
                    fab
                    x-small
                    right @click="(extra=true), (imc= false)"><v-icon>mdi-undo</v-icon></v-btn>
                    <br><br><br>
                    <v-container>
                    <v-row>
                      <v-col cols="6">
                         <v-text-field
                          :rules="[v=> !!v || '*Requerido']"
                          v-model="altura"
                          outlined
                          reverse
                          label="ALTURA"
                          type="number">
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                         <v-text-field
                         :rules="[v=> !!v || '*Requerido']"
                          v-model="peso"
                          outlined
                          reverse
                          label="PESO"
                          type="number">
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                         <v-text-field
                         :rules="[v=> !!v || '*Requerido']"
                          v-model="edad"
                          outlined
                          reverse
                          label="EDAD"
                          type="number">
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                       <v-radio-group v-model="checkRadio" row>
                          <v-radio  label="HOMBRE" value= 1 style="margin-left: 0px;margin-top: -41px"></v-radio>
                           <br>
                          <v-radio label="MUJER" style="margin-left: 0px;margin-top: -10px" value= 2></v-radio>
                        </v-radio-group>
                     </v-col>
                     <v-col cols="12">
                       <v-btn @click="calculoImc()" text block>CALCULAR</v-btn>
                     </v-col>
                     <v-col cols="12">
                      <h1 style="text-align:center;font-size:20px"> {{resultadoImc}} </h1>
                     </v-col>
                    </v-row>
                  </v-container>
            </v-card>
        </v-col>
  </v-row>
  <v-row v-if="perfil">
        <v-col cols="12">
            <v-card height="800px" elevation="22">
              <br>
                <h1 style="text-align:center;font-size:20px">{{titulo}}</h1>
                    <v-btn
                    absolute
                    fab
                    x-small
                    right @click="(extra=true), (perfil= false)"><v-icon>mdi-undo</v-icon></v-btn>
                    <br><br><br>
                    <h1 style="text-align:center;font-size:30px">{{ $store.state.nombreCliente  }}</h1>
                    <br>
                    <h1 style="text-align:center;font-size:20px">TELEFONO: {{ $store.state.telefono  }}</h1>
                    <br>
                    <h1 style="text-align:center;font-size:20px">INICIO DE RUTINA: {{   parseaFecha ($store.state.desde)  }}</h1>
                    <br>
                    <h1 style="text-align:center;font-size:20px">FIN DE RUTINA: {{  parseaFecha ($store.state.hasta)  }}</h1>
            </v-card>
        </v-col>
  </v-row>
  <v-row v-if="anotador">
        <v-col cols="12">
            <v-card height="800px" elevation="22">
              <br>
                <h1 style="text-align:center;font-size:20px">{{titulo}}</h1>
                    <v-btn
                    absolute
                    fab
                    x-small
                    right @click="(extra=true), (anotador= false)"><v-icon>mdi-undo</v-icon></v-btn>
                    <br><br><br>
                    <v-content>
                      <template>
                        <v-card
                          class="mx-auto"
                          width="350px"
                        >
                          <v-card-text>
                      <v-textarea v-model="nota" outlined label="ANOTAR"></v-textarea>
                      <v-btn @click="CrearAnotador()" block  x-large text>GUARDAR</v-btn>
                      <v-col style="margin-top: 0px; margin-left: -15px" v-for="(item, i) in notas" :key="i" cols="12">{{parseaFecha(item.fecha)}}   {{item.nombre}} <v-icon @click="eliminaNota(item.id)">mdi-delete</v-icon> <v-divider></v-divider></v-col>
                    </v-card-text>
                        </v-card>
                      </template>
                    </v-content>
            </v-card>
        </v-col>
  </v-row>

  <v-row v-if="cronometro">
        <v-col cols="12">
            <v-card height="300px" elevation="22">
              <br>
                <h1 style="text-align:center;font-size:20px">{{titulo}}</h1>
                    <v-btn
                    absolute
                    fab
                    x-small
                    right @click="(extra=true), (cronometro= false), limpiaCrono()"><v-icon>mdi-undo</v-icon></v-btn>
                    <br><br><br>
                    <h1 style="text-align:center;font-size:80px">{{ minC }}:{{ secM}}</h1>
            </v-card>

            <v-card height="520px">
                <template>
                    <v-col>
                    <p style="text-align:center;">SELECCIONE MINUTOS</p>
                    <br>
                    <v-content>
                      <template>
                        <v-card
                          class="mx-auto"
                          width="200px"
                        >
                          <v-card-text>
                            <v-text-field
                              outlined
                              reverse
                              type="number"
                              v-model="cronoMin"
                              label="MINUTOS"
                            ></v-text-field>
                          </v-card-text>
                        </v-card>
                      </template>
                  </v-content>
                    <br>

                </v-col>
                </template>
                <template>
                <v-row justify="space-around" class="mb-2">
                    <span class="group pa-2 ">
                        <v-btn x-large text  @click="crono">
                            {{ timer !== null ? "PAUSAR" : "INICIAR" }}
                        </v-btn>
                        <v-btn x-large text  @click="limpiaCrono()">
                            REINICIAR
                        </v-btn>
                    </span>
                </v-row>
               </template>
            </v-card>
        </v-col>
   </v-row>
    <v-row v-if="descanso">
        <v-col cols="12">
            <v-card height="300px" elevation="22">
              <br>
                <h1 style="text-align:center;font-size:20px">{{titulo}}</h1>
                    <v-btn
                    absolute
                    fab
                    x-small
                    right @click="(extra=true), (descanso= false)"><v-icon>mdi-undo</v-icon></v-btn>
                    <br><br><br>
                    <h1 style="text-align:center;font-size:80px">{{ zfill(min) }}:{{ zfill(sec) }}</h1>
            </v-card>
            <h1 style="text-align:center" v-show="verHistorial">
            <p v-for="interval in intervalList" :key="interval">
                PAUSA EN:  {{ interval }}
            </p>

            <v-btn outlined @click="clearIntervalList">LIMPIAR HISTÓRICO</v-btn>
            </h1>
            <v-card height="520px">
                <template>
                    <v-col>
                    <p style="text-align:center;">SELECCIONE RANGO DE DESCANZO</p>
                    <br><br>
                    <v-slider
                    thumb-label="always"
                    v-model="range"
                    :max="max"
                    step="10"
                    ></v-slider>
                    <br>

                    <v-progress-linear
                    v-model="knowledge"
                    height="25"
                    >
                    <strong>{{ Math.ceil(knowledge) }}%</strong>
                    </v-progress-linear>
                </v-col>
                </template>
                <template>
                    <br><br> <br>
                <v-row justify="space-around" class="mb-2">
                    <span class="group pa-2 ">
                        <v-btn x-large text  @click="play">
                            {{ timer !== null ? "PAUSAR" : "INICIAR" }}
                        </v-btn>
                        <v-btn x-large text  @click="clear">
                            REINICIAR
                        </v-btn>
                    </span>
                </v-row>
               </template>
            </v-card>
        </v-col>
   </v-row>
    <v-container v-if="extra" fluid>
      <v-btn
        absolute
        fab
        x-small
        right @click="$store.state.dialogCrono=false"><v-icon>mdi-close</v-icon>
      </v-btn>
      <br><br>
            <h1 style="text-align:center;font-size:40px">EXTRAS</h1>
      <v-row dense>
        <v-col
          v-for="card in cards"
          :key="card.title"
          :cols="card.flex"
        >
          <v-card >
            <v-img
              :src="card.src"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
              @click="eligeExtra(card.title)"
            >
              <v-card-subtitle >{{ card.title }}</v-card-subtitle>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    notas: [],
    nota: '',
    perfil: false,
    anotador: false,
    resultadoImc: '',
    altura: '',
    peso: '',
    edad: '',
    checkRadio: 1,
    titulo: '',
    cards: [
      { title: 'INDICE DE MASA MUSCULAR', src: 'https://www.12caracteristicas.com/wp-content/uploads/2019/08/indice-masa-corporal-357x210.jpg', flex: 12 },
      { title: 'TEMPORIZADOR', src: 'https://thumbs.dreamstime.com/b/plantilla-de-dise%C3%B1o-ilustraci%C3%B3n-vectorial-icono-estopwatch-stop-watch-160183505.jpg', flex: 6 },
      { title: 'TIEMPO DE DESCANSO', src: 'https://i.ibb.co/x5n82qW/tiempodes.png', flex: 6 },
      { title: 'PERFIL', src: 'https://static.thenounproject.com/png/638636-200.png', flex: 6 },
      { title: 'ANOTADOR', src: 'https://png.pngtree.com/png-vector/20210310/ourmid/pngtree-black-note-maker-png-image_3028797.jpg', flex: 6 }
    ],
    maxM: 60,
    imc: false,
    extra: true,
    descanso: false,
    cronometro: false,
    cronoMin: '',
    knowledge: 0,
    knowledgeM: 0,
    verHistorial: false,
    tope: 0,
    range: 60,
    max: 180,
    minimo: 0,
    sec: 0,
    min: 0,
    hour: 0,
    timer: null,
    intervalList: [],
    cronometroM: 0,
    startMin: 0,
    minC: 0,
    secM: 0,
    aux: 0
  }),
  methods: {
    eliminaNota (id) {
      const valor = confirm('ESTA SEGURO DE ELIMINAR ESTA NOTA')
      if (valor) {
        var myHeaders = new Headers()
        myHeaders.append('Authorization', 'Bearer ' + sessionStorage.token + '')
        // myHeaders.append('Authorization', 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IlBFUkFMVEEgIE1BUlRJTiIsImV4cCI6MTY2NjcwMjAyN30.zjw1YpvvPJK1B1EH2NPGZ9gUalembdu38fv2zLpD3jI')
        var raw = JSON.stringify({
          id: id
        })
        var requestOptions = {
          method: 'DELETE',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        }

        var promise = Promise.race([
          fetch(`${this.$store.state.url}anotador.php`, requestOptions)
            .then(response => response.json()),
          new Promise((resolve, reject) =>
            setTimeout(() => reject(new Error('Timeout')), 520000)
          )
        ])
        promise.then(result => console.log(result))
        promise.catch(error => console.log(error))
        setTimeout(() => { this.traerAnotador() }, 3000)
      }
    },
    CrearAnotador () {
      const obj = this
      var myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/json')

      var raw = JSON.stringify({
        opcion: 1,
        nombre: this.nota,
        fkcliente: this.$route.params.id,
        fecha: new Date().toISOString().substr(0, 10)
      })

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      }

      var promise = Promise.race([
        fetch(`${this.$store.state.url}anotador.php`, requestOptions)
          .then(response => response.json()),
        new Promise((resolve, reject) =>
          setTimeout(() => reject(new Error('Timeout')), 520000)
        )
      ])

      promise.then(result => console.log(result))
      promise.then(obj.$swal({
        title: 'Se creo con exito!!',
        text: '',
        icon: 'success'
      }))
      promise.catch(error => console.log(error))
      setTimeout(() => { this.traerAnotador() }, 3000)
    },
    traerAnotador () {
      const obj = this
      var myHeaders = new Headers()
      myHeaders.append('Authorization', 'Bearer ' + sessionStorage.token + '')
      var raw = JSON.stringify({
        fkcliente: this.$route.params.id,
        opcion: '2'
      })
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      }

      var promise = Promise.race([
        fetch(`${this.$store.state.url}anotador.php`, requestOptions)
          .then(response => response.json()),
        new Promise((resolve, reject) =>
          setTimeout(() => reject(new Error('Timeout')), 520000)
        )
      ])
      promise.then(result => (obj.notas = result))
      promise.then(result => console.log(result))
      promise.catch(error => console.log(error))
    },
    parseaFecha (fec) {
      const arrayFehahechos = fec.split(['T'])
      let auxFechahec = arrayFehahechos[0]
      auxFechahec = auxFechahec.split(['-'])
      return (auxFechahec =
        auxFechahec[2] + '-' + auxFechahec[1] + '-' + auxFechahec[0])
    },
    calculoImc () {
      this.resultadoImc = ''
      if (this.altura > 0 && this.edad > 0 && this.checkRadio && this.peso > 0) {
        // buscamos el peso idoneo
        var pesoIdeal = 50 + ((this.altura - 150) / 4) * 3 + (this.edad - 20) / 4
        if (this.checkRadio === '2') {
          pesoIdeal = pesoIdeal * 0.9
        }
        pesoIdeal = Math.ceil(pesoIdeal)
        var respuesta = ''
        if (pesoIdeal > this.peso) {
          respuesta = 'TU PESO IDEAL SERIA: ' + pesoIdeal + ' KILOS \n'
          respuesta += 'TE FALTAN: ' + (pesoIdeal - this.peso).toFixed(2) + 'KILOS'
        } else if (pesoIdeal < this.peso) {
          respuesta = 'TU PESO IDEAL SERIA: ' + pesoIdeal + ' KILOS \n'
          respuesta += 'KILOS DEMAS: ' + (this.peso - pesoIdeal).toFixed(2) + ''
        } else {
          respuesta = 'PESO IDEAL!!!'
        }
        this.resultadoImc = respuesta
      } else {
        // mostramos un mensaje de error si alguno de los valores no es correcto
        respuesta = 'ALGUNO DE LOS DATOS ES INCORRECTO'
        this.resultadoImc = respuesta
      }
    },
    crono () {
      var obj = this
      this.startMin = parseInt(this.cronoMin) * 60
      console.log(this.startMin)
      if (this.timer === null) {
        this.timer = setInterval(function () {
          obj.cronometroM++
          obj.aux = parseInt(obj.startMin) - parseInt(obj.cronometroM)
          obj.minC = Math.floor(obj.aux / 60)
          if (obj.secM <= 60 && obj.secM > 0) {
            obj.secM--
            if (obj.secM < 10 && obj.secM > 0) {
              obj.secM = '0' + obj.secM
            } else if (obj.secM === 0) {
              obj.secM = '00'
            }
          } else {
            obj.secM = 59
          }
          obj.paraCronometro()
        }, 1000)
      } else {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    paraCronometro () {
      var obj = this
      console.log(this.secM + ' ' + this.minC)
      if (this.secM === '00' && this.minC === 0) {
        clearInterval(obj.timer)
        this.cronometroM = 0
        this.startMin = 0
        this.minC = 0
        this.secM = 0
        this.aux = 0
        this.cronoMin = ''
        setTimeout(() => { obj.timer = null }, 1000)
        this.playSound('http://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3')
        this.$swal({
          title: 'FINALIZO!!',
          text: '',
          icon: 'success'
        })
      }
    },
    limpiaCrono () {
      clearInterval(this.timer)
      this.timer = null
      this.cronometroM = 0
      this.startMin = 0
      this.minC = 0
      this.secM = 0
      this.aux = 0
      this.cronoMin = ''
    },
    eligeExtra (title) {
      this.titulo = title
      switch (title) {
        case 'TIEMPO DE DESCANSO' :
          this.descanso = true
          this.extra = false
          break
        case 'TEMPORIZADOR' :
          this.cronometro = true
          this.extra = false
          break
        case 'INDICE DE MASA MUSCULAR' :
          this.imc = true
          this.extra = false
          break
        // eslint-disable-next-line no-duplicate-case
        case 'PERFIL' :
          this.perfil = true
          this.extra = false
          break
        case 'ANOTADOR' :
          this.traerAnotador()
          this.anotador = true
          this.extra = false
          break
      }
    },
    playSound (sound) {
      if (sound) {
        var audio = new Audio(sound)
        audio.play()
      }
    },
    zfill (number) {
      return number.toString().padStart(2, 0)
    },
    play () {
      if (this.timer === null) {
        this.playing()
        this.timer = setInterval(() => this.playing(), 1000)
      } else {
        clearInterval(this.timer)
        this.timer = null
        this.pause()
      }
    },
    playing () {
      const barra = 100 / this.range
      this.knowledge += barra
      this.tope++
      this.sec++
      if (this.tope === this.range) {
        this.clear()
        this.playSound('http://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3')
        this.$swal({
          title: 'COMIENZA AHORA!!',
          text: '',
          icon: 'success'
        })
        this.tope = 0
        this.knowledge = 0
      }
      if (this.sec >= 59) {
        this.sec = 0
        this.min++
      }
      if (this.min >= 59) {
        this.min = 0
        this.hour++
      }
    },
    pause () {
      this.intervalList.push(
        `${this.zfill(this.hour)}:${this.zfill(this.min)}:${this.zfill(
          this.sec
        )}`
      )
    },
    clear () {
      if (this.timer !== null) {
        clearInterval(this.timer)
        this.timer = null
      }
      this.sec = 0
      this.min = 0
      this.hour = 0
      this.knowledge = 0
      this.clearIntervalList()
    },
    clearIntervalList () {
      this.intervalList = []
    }
  }
}
</script>

<style scoped>
  .v-text-field input {
    font-size: 2em;
  }
  .group {
  display: flex;
  flex: 1;
  justify-content: space-around;
}

.img {
  width: 420px;
  height: 420px;
  padding-top: 100px;
}
.timer {
  color: rgb(5, 53, 245);
  font-size: 70px;
}
.btns {
  margin-top: 155px;
  display: flex;
}
.btn {
  width: 150px;
  background-color: rgb(38, 91, 206);
  font-size: 20px;
  border: none;
  border-radius: 5px;
  text-align: center;
  padding: 6px;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
}
.btn-margin {
  margin: 0px 7px;
}
.btn:hover {
  opacity: 0.8;
}
.interval {
  color: rgb(134, 63, 221);
  flex: 1;
  width: 420px;
  margin-top: 15px;
}
.interval ul {
  text-align: center;
}
.interval ul li {
  list-style: none;
  background-color: rgb(70, 70, 70);
  padding: 15px;
  margin-bottom: 10px;
}
</style>
